import Device from '../services/device-state';
import BannerType from './banner-type';

class AdUnit {
  /** The HTML-element of the ad unit. */
  readonly element: Element;
  /** The ad unit type. */
  readonly type: BannerType;
  /** The ad unit prefix */
  readonly prefix: string;

  private static readonly prefixAttr = 'ad-prefix';
  private static readonly typeMobileAttr = 'ad-type-mobile';
  private static readonly typeDesktopAttr = 'ad-type-desktop';

  private constructor(
    element: Element,
    type: BannerType,
    prefix: string) {
    this.element = element;
    this.type = type;
    this.prefix = prefix || null;
  }

  /** The ID of the ad unit - contains the full ID if autoIncrement is false. */
  get id(): string {
    return this.element.id;
  }
  set id(value: string) {
    this.element.id = value;
  }

  get isSkyscraper(): boolean {
    return this.type === BannerType.StickyTallLeft
      || this.type === BannerType.StickyTallRight;
  }

  /**
   * Maps an element to an ad unit.
   * If given an invalid element it returns null.
   * @param element The ad unit element.
   * @returns An ad unit.
   */
  public static map(element: Element): AdUnit {
    if (!element) return null;

    const typeAttribute = this.shouldLoadDesktopTypes() ? this.typeDesktopAttr : this.typeMobileAttr;
    const type = element.getAttribute(typeAttribute);
    const prefix = element.getAttribute(this.prefixAttr);

    if (!type) return null;

    return new AdUnit(element, type as BannerType, prefix);
  }

  /** Generates the full ID to be used by the header bidder. */
  public generateId(number: number, postFix: number): string {
    let id = '';

    if (this.prefix)
      id += `${this.prefix}_`;

    id += `${this.type}-${number}`;

    if (postFix)
      id += `_${postFix}`;

    return id;
  }

  public static shouldLoadDesktopTypes(): boolean {
    return Device.isDesktop || (Device.isTablet && Device.isLandscapeOrientation());
  }
}

export default AdUnit;
