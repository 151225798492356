import BannerType from './banner-type';
import AdUnit from './ad-unit';
import AdUnitContext from './ad-unit-context';

document.addEventListener("DOMContentLoaded", (event) => {
  window.pendingScriptExecutions.push({
    callback: function () {
      init();
    }, context: this
  });
});

/** The observer used to observe when an element is inserted in the DOM. The callback initializes injected AdUnits. */
let mutationObserver: MutationObserver;

/** Used in the init function to check if it already has been initialized. */
let initialized: boolean = false;

/**
 * Pushes an ad unit to the header bidder.
 * @param adElement The ad element that should be pushed.
 */
const pushAd = function (adElement: Element): void {
  let adUnit = AdUnit.map(adElement);
  if (!adUnit) {
    console.error('Could not push invalid ad unit');
    return;
  }

  if (adUnit.isSkyscraper && !doesWindowFitSkyscrapers()) {
    console.warn('Could not push skyscraper as it does not fit the window');
    return;
  }

  const context = AdUnitContext.getSet(adUnit.type);
  if (!context) {
    console.error('Could not find ad unit context for type:', adUnit.type);
    return;
  };

  let nextNumber = context.getNextAvailableNumber();

  adUnit.id = adUnit.generateId(nextNumber.number, nextNumber.postFix);

  assignParentCssClass(adUnit);
}

const assignParentCssClass = function (adUnit: AdUnit): void {
  const adUnitBaseClass = 'ad-unit';
  const cssClasses: string[] = [];

  cssClasses.push(adUnitBaseClass);

  switch (adUnit.type as BannerType) {
    case BannerType.MobileTopScroll:
    case BannerType.DesktopTopScroll:
      cssClasses.push(`${adUnitBaseClass}--topscroller-io`);
      break;

    case BannerType.MobileOutstream:
    case BannerType.DesktopOutstream:
      cssClasses.push(`${adUnitBaseClass}--in-article`);
      break;

    case BannerType.DesktopBodyWide:
      cssClasses.push(`${adUnitBaseClass}--body-wide`);
      break;

    case BannerType.MobileBodySquare:
    case BannerType.DesktopBodySquare:
      cssClasses.push(`${adUnitBaseClass}--body-square`);
      break;

    case BannerType.MobileHeader:
    case BannerType.DesktopHeaderWide:
      cssClasses.push(`${adUnitBaseClass}--header-wide`);
      break;

    case BannerType.StickyTallLeft:
      cssClasses.push(`${adUnitBaseClass}--sticky-tall-left`);
      break;

    case BannerType.StickyTallRight:
      cssClasses.push(`${adUnitBaseClass}--sticky-tall-right`);
      break;
  }

  for (let cssClass of cssClasses) {
    adUnit.element.classList.add(cssClass);
  }
}

const doesWindowFitSkyscrapers = function (): boolean {
  const skyscraperWidth = 160;
  const containerWidth = 960;
  const gutterWidth = 20;
  const minWindowWidth = containerWidth + gutterWidth + (skyscraperWidth * 2);
  return window.matchMedia(`(min-width: ${minWindowWidth}px)`).matches;
}

/** Initializes the manager. */
const init = function () {
  if (initialized) return;

  const adUnits = document.querySelectorAll('div[ad]');
  console.debug('Initializing ads! Count:', adUnits.length);

  // Initialize all adds
  for (let adUnit of adUnits) {
    pushAd(adUnit);
  }

  initMutationObserver();

  initialized = true;
}

/** Initializes the mutation observer that ensures all injected ad units get initialized. */
const initMutationObserver = function () {
  if (mutationObserver) return;

  const callback: MutationCallback = (mutations, observer) => {
    let addedNodes = mutations
      .flatMap(mutation => mutation.addedNodes)
      .reduce((accumulator: Node[], value) => accumulator.concat(Array.from(value)), [])
      .filter(node => node.nodeType === 1 && node instanceof Element)
      .map(node => node as Element);

    const matches = [
      ...addedNodes.filter(element => element.matches('[ad]')),
      ...addedNodes.flatMap(element => [...element.querySelectorAll('[ad]')])
    ];

    matches
      // Filter duplicates
      .filter((value, index, self) => self.indexOf(value) === index)
      // Initialize all injected ad units
      .forEach(element => { pushAd(element); });
  }

  mutationObserver = new MutationObserver(callback);

  const mutationConfig: MutationObserverInit = {
    childList: true,
    subtree: true,
    attributes: false,
    characterData: false,
    attributeOldValue: false,
    characterDataOldValue: false
  };

  mutationObserver.observe(document.body, mutationConfig);
}

export default {
  init
};
