enum BannerType {
  DesktopTopScroll = "topscroller-IO",
  DesktopHeaderWide = "header-wide",
  DesktopBodyWide = "body-wide",
  DesktopBodySquare = "body-square",
  DesktopOutstream = "in-article",
  MobileTopScroll = "mobile-topscroller-IO",
  MobileHeader = "mobile-header",
  MobileBodySquare = "mobile-body-square",
  MobileOutstream = "mobile-in-article",
  StickyTallLeft = "sticky-tall-left",
  StickyTallRight = "sticky-tall-right",
}

export default BannerType;
